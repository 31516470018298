
import { defineComponent, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import {
  ElButton,
  ElIcon,
  ElForm,
  ElFormItem,
  FormRules,
  FormInstance,
  ElCheckboxGroup,
  ElCheckbox,
  ElNotification,
} from 'element-plus'
import { Back } from '@element-plus/icons-vue'

import { routeNames } from '@/router'
import { EPermissions, ICreateUserForm } from '@/types'
import { usePermissions } from '@/core/compositions'
import { apiErrorNotification } from '@/core/helpers'
import { usersService } from '@/services'

export default defineComponent({
  name: 'CreateUserView',

  components: {
    ElButton,
    ElIcon,
    Back,
    ElForm,
    ElFormItem,
    ElCheckboxGroup,
    ElCheckbox,
  },

  setup() {
    const router = useRouter()

    const { groupedPermissionsWithLabels } = usePermissions()

    const loading = ref(false)
    const checkAll = ref(false)
    const isIndeterminate = ref(true)

    const formRef = ref<FormInstance>()
    const formValues = reactive<ICreateUserForm>({
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      permissions: [],
    })

    const formValidationRules = reactive<FormRules>({
      email: [
        {
          required: true,
          message: 'Email address is required!',
          trigger: 'change',
        },
        {
          type: 'email',
          message: 'Invalid email address!',
          trigger: 'change',
        },
      ],
      firstName: [
        {
          required: true,
          message: 'First name is required!',
          trigger: 'change',
        },
        {
          min: 1,
          max: 255,
          message: 'First name length should be 1 to 255',
          trigger: 'change',
        },
      ],
      lastName: [
        {
          required: true,
          message: 'Last name is required!',
          trigger: 'change',
        },
        {
          min: 1,
          max: 255,
          message: 'Last name length should be 1 to 255',
          trigger: 'change',
        },
      ],
      password: [
        {
          required: true,
          message: 'Password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'Password length should be 10 to 64',
          trigger: 'change',
        },
      ],
      confirmPassword: [
        {
          required: true,
          message: 'Confirm password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'Confirm password length should be 10 to 64',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value !== formValues.password) {
              callback(new Error('Passwords not matches!'))
            } else {
              callback()
            }
          },
          message: 'Passwords not matches!',
          trigger: 'change',
        },
      ],
    })

    const onAddAllPermissionsChange = (value: boolean) => {
      formValues.permissions = value ? Object.values(EPermissions) : []
      isIndeterminate.value = false
    }

    const onAddPermissionChange = (value: string[]) => {
      const checkedCount = value.length
      checkAll.value =
        checkedCount === groupedPermissionsWithLabels.value.length
      isIndeterminate.value =
        checkedCount > 0 &&
        checkedCount < groupedPermissionsWithLabels.value.length
    }

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await usersService.create(formValues)

        ElNotification({
          message: 'User successfully created!',
          type: 'success',
        })

        toUsersPage()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const toUsersPage = () => {
      router.push({ name: routeNames.users })
    }

    return {
      loading,
      checkAll,
      isIndeterminate,
      toUsersPage,
      formValues,
      formValidationRules,
      formRef,
      groupedPermissionsWithLabels,
      onAddAllPermissionsChange,
      onAddPermissionChange,
      onSubmit,
    }
  },
})
